import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { getBrText } from "../../helpers/helper.rendering";

const Team = () => {

    const { realEstate } = useStaticQuery(graphql`
     query {
         realEstate {
             sections {
                 about {
                    team {
                        hidden
                        title
                        paragraph
                        subtitle
                        image
                        hiddenItems
                        items{
                            name
                            type
                            broker_id
                        }
                    }
                }
             }
         }
     }`)

     console.log(realEstate.sections.about.team.hiddenItems)

    return realEstate.sections ? (
        !realEstate.sections.about.team.hidden && (
            <section class="the-team" style={{backgroundImage:"url("+ realEstate.sections.about.team.image +")"}}>
                <div className="opacity"></div>
                <div class="container-fluid position-relative">
                    <div class="row">
                        <div class="col-lg-2">
                            <p class="section-name">
                                {realEstate.sections.about.team.title}
                            </p>
                        </div>
                        <div class={"col-lg-10 mb-lg-5 "}>
                            <h2 class="text-uppercase">
                                {getBrText(realEstate.sections.about.team.paragraph).map((item, index) => (
                                    <>
                                        {item}
                                        <br  className='d-none d-lg-block'/>
                                    </>
                                ))}
                
                            </h2>
                        </div>
                        <div class={"col-lg-10 offset-lg-2 mt-lg-4 my-5 mb-lg-0 "  + (realEstate.sections.about.team.hiddenItems && 'd-none')}>
                            <h2 class="my-4 my-lg-0">
                                {realEstate.sections.about.team.subtitle}
                            </h2>
                        </div>
                        <div class={"col-lg-10 offset-lg-2 mt-lg-4 "  + (realEstate.sections.about.team.hiddenItems && 'd-none')}>
                            <div class="row mt-lg-5">
                                {realEstate.sections.about.team.items.map((item, index) => (
                                    <div class="col-lg-4 mb-lg-5">
                                        <div class="card-personal">
                                            {/* <div class="arrow"><i class="icon-arrow" style={{transform:"rotate(90deg)"}}></i></div> */}
                                            <div>
                                                <h4>{item.name}</h4>
                                                <p>{item.type}</p>
                                                <p>{item.broker_id}</p>
                                            </div>
                
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        // ):''
    )):''
}

export default Team;